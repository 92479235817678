/* eslint-disable */
(function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
        ElementProto.matches =
            ElementProto.msMatchesSelector ||
            ElementProto.mozMatchesSelector ||
            ElementProto.webkitMatchesSelector ||
            function matches(selector) {
                var element = this;
                var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
                var index = 0;

                while (elements[index] && elements[index] !== element) {
                    ++index;
                }

                return Boolean(elements[index]);
            };
    }

    if (typeof ElementProto.closest !== 'function') {
        ElementProto.closest = function closest(selector) {
            var element = this;

            while (element && element.nodeType === 1) {
                if (element.matches(selector)) {
                    return element;
                }

                element = element.parentNode;
            }

            return null;
        };
    }
})(window.Element ? window.Element.prototype : window.HTMLElement.prototype);

(function () {
    var rootElement = document.documentElement || document.body;

    var xHttpRequestFunc = function (requestType, url, cm_form) {
        var cm_email_input = cm_form.querySelector('.js-cm-email-input');
        var data = 'email=' + encodeURIComponent(cm_email_input.value) + '&data=' + encodeURIComponent(cm_form.getAttribute('data-id'));

        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                cm_form.action = this.responseText;
                cm_form.submit();
            }
        };

        xhttp.open(requestType, url, true);
        xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhttp.send(data);
    };

    var eventCallback = function (e) {
        var target = e.target;
        var cm_form = target.closest('.js-cm-form');

        if (!cm_form) {
            return;
        }

        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        xHttpRequestFunc('POST', '{schemeAndDomain}/t/getsecuresubscribelink', cm_form);
    };

    function registerHandler(target, type, callback) {
        if (target.addEventListener) {
            target.addEventListener(type, callback);
        } else {
            target.attachEvent('on' + type, callback);
        }
    }

    if (!rootElement.getAttribute('data-cm-hook')) {
        registerHandler(rootElement, 'submit', eventCallback);
        rootElement.setAttribute('data-cm-hook', '1');
    }
})();

(function () {
    var mobileNumberField = document.getElementsByName('cm-mobile-number')[0];
    var mobileNumberCountryField = document.getElementsByName('cm-mobile-number-country')[0];
    var smsMarketingConsentCheck = document.getElementsByName('cm-sms-marketing-consent')[0];
    var smsMarketingConsentRequiredLabel = document.getElementById('cm-sms-marketing-consent-required-label');
    var previousValue = '';
    var isDeleteKeyDown = false;
    if (!mobileNumberField || !mobileNumberCountryField || !smsMarketingConsentCheck) {
        return;
    }

    var mobileInputCountryName = {
        UnitedStatesOfAmerica: 'United States of America',
        Canada: 'Canada',
        Australia: 'Australia',
        UnitedKingdom: 'United Kingdom',
    };

    var getMobileInputPropsByCountry = function (country) {
        switch (country) {
            case mobileInputCountryName.Australia:
                return {
                    formatter: function formatter(input) {
                        var newValue = input.replace(/\D/g, '').match(/(\d{0,4})(\d{0,3})(\d{0,3})/) || [];
                        return newValue[1] + (newValue[2] ? ' ' + newValue[2] : '') + (newValue[3] ? ' ' + newValue[3] : '');
                    },
                    placeholder: 'E.g. 0491 570 006',
                    pattern: '[0-9]{4} [0-9]{3} [0-9]{3}',
                    maxLengthOfMobileNumberWithoutSeparators: 10,
                    indicesOfSeparatorInDigitsOnlyMobileNumber: [4, 7],
                };
            case mobileInputCountryName.UnitedKingdom:
                return {
                    formatter: function formatter(input) {
                        var newValue = input.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/) || [];
                        return newValue[1] + (newValue[2] ? ' ' + newValue[2] : '') + (newValue[3] ? ' ' + newValue[3] : '');
                    },
                    placeholder: 'E.g. 077 0090 0000',
                    pattern: '[0-9]{3} [0-9]{4} [0-9]{4}',
                    maxLengthOfMobileNumberWithoutSeparators: 11,
                    indicesOfSeparatorInDigitsOnlyMobileNumber: [3, 7],
                };
            case mobileInputCountryName.Canada:
            case mobileInputCountryName.UnitedStatesOfAmerica:
            default:
                return {
                    formatter: function formatter(input) {
                        var newValue = input.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || [];
                        return newValue[1] + (newValue[2] ? '-' + newValue[2] : '') + (newValue[3] ? '-' + newValue[3] : '');
                    },
                    placeholder: 'E.g. 123-456-7890',
                    pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                    maxLengthOfMobileNumberWithoutSeparators: 10,
                    indicesOfSeparatorInDigitsOnlyMobileNumber: [3, 6],
                };
        }
    };

    var updateMobileInputAttributes = function (country) {
        var mobileInputProps = getMobileInputPropsByCountry(country);
        var placeholder = mobileInputProps.placeholder;
        var pattern = mobileInputProps.pattern;

        mobileNumberField.value = '';
        mobileNumberField.placeholder = placeholder;
        mobileNumberField.pattern = pattern;
    };

    var updateSmsMarketingConsentCheckRequired = function (state) {
        if (state) {
            smsMarketingConsentCheck.setAttribute('required', '');
        } else {
            smsMarketingConsentCheck.removeAttribute('required');
        }

        if (smsMarketingConsentRequiredLabel) {
            if (state) {
                smsMarketingConsentRequiredLabel.removeAttribute('hidden');
            } else {
                smsMarketingConsentRequiredLabel.setAttribute('hidden', '');
            }
        }
    };

    var findAllNonDigitIndices = function (value) {
        var nonDigitRegex = /\D/g;
        var nonDigitIndices = [];
        var match;
        while ((match = nonDigitRegex.exec(value)) !== null) {
            nonDigitIndices.push(match.index);
        }

        return nonDigitIndices;
    };

    var mobileNumberFieldChangeHandler = function (event) {
        // Handle ie11 misfiring input events when there is no change
        if (previousValue === event.currentTarget.value || (!previousValue && event.currentTarget.value === '')) {
            return;
        }

        var country = mobileNumberCountryField.value;
        var mobileInputProps = getMobileInputPropsByCountry(country);
        var formatter = mobileInputProps.formatter;
        var maxLengthOfMobileNumberWithoutSeparators = mobileInputProps.maxLengthOfMobileNumberWithoutSeparators;
        var indicesOfSeparatorInDigitsOnlyMobileNumber = mobileInputProps.indicesOfSeparatorInDigitsOnlyMobileNumber;

        var cursorLocation = event.currentTarget.selectionEnd || 0;
        var nonDigitIndices = findAllNonDigitIndices(event.currentTarget.value);
        var lengthOfDigitsOnlyValue = event.currentTarget.value.length - nonDigitIndices.length;
        if (lengthOfDigitsOnlyValue > maxLengthOfMobileNumberWithoutSeparators) {
            // do not allow update once any edits fill the string and keep the cursor where it is.
            var increaseInCharacters = event.currentTarget.value.length - previousValue.length;
            event.currentTarget.value = previousValue;
            event.currentTarget.setSelectionRange(cursorLocation - increaseInCharacters, cursorLocation - increaseInCharacters);
        } else {
            var cursorLocationInDigitOnlyValue =
                cursorLocation -
                nonDigitIndices.filter(function (nonDigitIndex) {
                    return nonDigitIndex < cursorLocation;
                }).length;
            var formatterAddedSeparatorsBeforeCursorLocation = indicesOfSeparatorInDigitsOnlyMobileNumber.filter(function (separatorIndex) {
                return separatorIndex < cursorLocationInDigitOnlyValue;
            }).length;
            if (isDeleteKeyDown) {
                // place cursor after separators
                formatterAddedSeparatorsBeforeCursorLocation = indicesOfSeparatorInDigitsOnlyMobileNumber.filter(function (separatorIndex) {
                    return separatorIndex <= cursorLocationInDigitOnlyValue;
                }).length;
            }
            var newCursorLocation = cursorLocationInDigitOnlyValue + formatterAddedSeparatorsBeforeCursorLocation;
            var formattedValue = (event.currentTarget.value = formatter(event.currentTarget.value));

            event.currentTarget.setSelectionRange(newCursorLocation, newCursorLocation);
            previousValue = event.currentTarget.value;
            event.currentTarget.value = formattedValue;
            updateSmsMarketingConsentCheckRequired(Boolean(formattedValue));
        }

        previousValue = event.currentTarget.value;
    };

    var mobileNumberCountryFieldChangeHandler = function (event) {
        updateMobileInputAttributes(event.currentTarget.value);
    };

    var onKeyDownEventHandler = function (event) {
        isDeleteKeyDown = event.key === 'Delete' || event.keyCode === 46;
    };

    var onKeyUpEventHandler = function (event) {
        isDeleteKeyDown = false;
    };

    updateMobileInputAttributes(mobileNumberCountryField.value);
    mobileNumberField.addEventListener('input', mobileNumberFieldChangeHandler);
    mobileNumberField.addEventListener('keydown', onKeyDownEventHandler);
    mobileNumberField.addEventListener('keyup', onKeyUpEventHandler);
    mobileNumberCountryField.addEventListener('change', mobileNumberCountryFieldChangeHandler);
})();
